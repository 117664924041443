export default defineNuxtRouteMiddleware(async (to) => {
	const { query } = to;
	const { reviewIds } = query as { reviewIds: string };
	const { setReviewIds } = useReviewIds();

	if (!reviewIds) {
		setReviewIds([]);
		return;
	}

	setReviewIds(reviewIds.split(","));
	return;
});
